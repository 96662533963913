.switch-wrapper {
	position: relative;
	top: 2px;
}
#switch {
	position: absolute;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	cursor: pointer;
}
.switch_label {
	position: relative;
	cursor: pointer;
	display: inline-block;
	width: 32px;
	height: 20px;
	background: #dddddd;
	border-radius: 62.5px;
	transition: 0.2s;
}

.onf_btn {
	position: absolute;
	top: 1px;
	left: 1px;
	display: inline-block;
	width: 18px;
	height: 18px;
	border-radius: 50%;
	background: #fff;
	transition: 0.2s;
}

#switch:checked + .switch_label {
	background-color: #ffbc00;
}

#switch:checked + .switch_label .onf_btn {
	left: 13px;
	background-color: #fff;
}

.none-box {
	text-align: center;
	padding: 1.5vw 0 3vw 0;
}
.none-box .none-text {
	color: #888;
	margin-top: 16px;
	font-family: KBFG Display;
	font-size: 16px;
	font-style: normal;
	font-weight: 300;
	line-height: normal;
}
.mentor-none {
	padding: 5vw 0 3vw 0;
}

@media screen and (max-width: 768px) {
	.none-box {
		padding: 2.5vw 0 5vw 0;
	}
	.none-box svg {
		width: 32px;
	}
	.none-box .none-text {
		color: #888;
		margin-top: 12px;
		font-family: KBFG Display;
		font-size: 14px;
		font-style: normal;
		font-weight: 300;
		line-height: normal;
	}
}
